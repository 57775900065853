<template>
  <el-form
    ref="form"
    style="flex: 1; margin-top: 20px; margin-left: 40px; margin-bottom: 80px"
    :model="formData"
    label-position="left"
    label-width="100px"
  >
    <el-form-item label="邮箱类型" required>
      <el-select
        v-model="formData.emailType"
        size="small"
        style="width: 270px"
        placeholder="请选择邮箱类型"
        clearable
      >
        <el-option label="腾讯企业邮箱" value="1"></el-option>
      </el-select>
    </el-form-item>


    <el-form-item label="订单类型" required>
      <el-select
        v-model="formData.emailOrderType"
        size="small"
        style="width: 270px"
        placeholder="请选择订单类型"
        @change="emailOrderTypeChange"
        clearable
      >
        <el-option label="新购订单" value="1"></el-option>
        <el-option label="续费订单" value="2"></el-option>
        <el-option label="扩容订单" value="3"></el-option>
        <el-option label="特价订单" value="4"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="优惠类别">
      <el-checkbox-group v-model="formData.categoryRetList">
        <el-checkbox v-show="isBuy" v-for="category in categoryBuyList" :label="category.id" :key="category.id">{{category.name}}
        </el-checkbox>
        <el-checkbox v-show="isRenew" v-for="category in categoryRenewList" :label="category.id" :key="category.id">{{category.name}}
        </el-checkbox>
        <el-checkbox v-show="isDilatation" v-for="category in categoryDilatationList" :label="category.id" :key="category.id">{{category.name}}
        </el-checkbox>

        <el-checkbox v-show="isSpecial" v-for="category in categorySpecialList" :label="category.id" :key="category.id">{{category.name}}
        </el-checkbox>
        
      </el-checkbox-group>
  </el-form-item>
    
    <el-form-item label="用户数" required>
      <el-input
        style="width: 270px"
        v-model.number="formData.number"
        size="small"
        placeholder="请输入用户数"
        clearable
        oninput="value=value.replace(/[^\d]/g,'')"
      ></el-input>
    </el-form-item>
    <el-form-item label="购买年限" required>
      <el-input
        style="width: 270px"
        v-model="formData.term"
        size="small"
        placeholder="请输入购买年限"
        clearable
        oninput="value=value.replace(/[^\d]/g,'')"
      ></el-input>
    </el-form-item>

    <el-form-item label="扩容数据：">
    </el-form-item>
    <el-form-item label="开始日期">
      <el-date-picker
        style="width: 270px"
        value-format="yyyy-MM-dd"
        size="small"
        v-model="formData.startTime"
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="到期日期">
      <el-date-picker
        style="width: 270px"
        value-format="yyyy-MM-dd"
        size="small"
        v-model="formData.expireTime"
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="扩容用户数">
      <el-input
        style="width: 270px"
        v-model.number="formData.dilatationNumber"
        size="small"
        placeholder="请输入用户数"
        clearable
        oninput="value=value.replace(/[^\d]/g,'')"
      ></el-input>
    </el-form-item>

    <el-button
      round
      type="primary"
      style="
        background: #2370eb;
        border-color: #2370eb;
        color: #fff;
        width: 270px;
        margin-left: 50px;
      "
      size="mini"
      @click="onCalculation"
      >计算</el-button
    >
    <p style="height: 50px"></p>
    <el-form-item
      label="总价："
      v-mode="showEl"
      v-if="showEl == true"
      type="hidden"
    >
      <p style="font-size: 14px">
        {{ formData.price }}
      </p>
    </el-form-item>
    <el-form-item
      label="折扣："
      v-mode="showEl"
      v-if="showEl == true"
      type="hidden"
    >
      <p style="font-size: 14px">
        {{  
          returnRebate(formData.rebate)
        }}
      </p>
    </el-form-item>
    <el-form-item
      label="结算价："
      v-mode="showEl"
      v-if="showEl == true"
      type="hidden"
    >
      <p style="font-size: 14px">
        {{ formData.formatAmount }}
      </p>
    </el-form-item>
    <el-form-item
      label="扩容结算价："
      v-mode="showEl"
      v-if="showEl == true"
      type="hidden"
    >
      <p style="font-size: 14px">{{ formData.dilatationAmount }}</p>
    </el-form-item>
  </el-form>
</template>
<script>
import { calculationNew } from "@/api/cs/tool.js";
export default {
  data() {
    return {
      formData: {
        emailType: "1",
        number: "",
        dilatationNumber: "",
        term: "",
        expireTime: "",
        startTime: "",
        formatAmount: "",
        dilatationAmount: "",
        emailOrderType: "1",
        categoryRetList: [],
        price: "",
        rebate: ""
      },
      categoryBuyList: [
            { id: 1,  name: '月度服务费' },
            { id: 2,  name: '新购订单' },
            { id: 3,  name: '季度奖励' },
            ],
      categoryRenewList: [
            { id: 4,  name: '月度服务费' },
            { id: 5,  name: '金额续费率' },
            { id: 6,  name: '企业续费率' },
            { id: 7,  name: '季度奖励' },
            ],
      categoryDilatationList: [
            { id: 8,  name: '月度服务费' },
            { id: 9,  name: '金额续费率' },
            { id: 10,  name: '企业续费率' },
            { id: 11,  name: '季度奖励' },
            ],
      categorySpecialList: [
            { id: 12,  name: '特价订单返利' },
            ],
      isBuy: true,
      isRenew: false,
      isDilatation: false,
      isSpecial:false,
      showEl: false,
    };
  },
  methods: {
    onCalculation() {
      console.log(this.formData.categoryRetList);
      if (!this.formData.number) {
        this.$message.error("请输入用户数");
        return;
      }
      if (this.formData.number < 5) {
        this.$message.error("用户数不能少于5");
        return;
      }
      if (!this.formData.term) {
        this.$message.error("请输入年限");
        return;
      }
      this.showEl = true;
      let data = {
        param: {
            productCategory: this.formData.emailType,
            categoryList: this.formData.categoryRetList,
            number: this.formData.number,
            term: this.formData.term,
            expireTime: this.formData.expireTime,
            startTime: this.formData.startTime,
            dilatationNumber: this.formData.dilatationNumber,
        },
      };

      var that = this;
      if (this.domain != "") {
        calculationNew(data).then((res) => {
          console.log(res);
          //this.data = res.data;
          that.formData.formatAmount = res.data.formatAmount;
          that.formData.dilatationAmount = res.data.dilatationAmount;
          that.formData.price = res.data.price;
          that.formData.rebate = res.data.rebate;
          
        });
      }
      
    },
    emailOrderTypeChange() {
      if (this.formData.emailOrderType == 1) {
        this.isBuy = true;
        this.isRenew = false;
        this.isDilatation = false;
        this.isSpecial = false;
        this.formData.categoryRetList = [];
      } else if (this.formData.emailOrderType == 2) {
        this.isBuy = false;
        this.isRenew = true;
        this.isDilatation = false;
        this.isSpecial = false;
        this.formData.categoryRetList = [];
      } else if (this.formData.emailOrderType == 3) {
        this.isBuy = false;
        this.isRenew = false;
        this.isDilatation = true;
        this.isSpecial = false;
        this.formData.categoryRetList = [];
      }else{
        this.isBuy = false;
        this.isRenew = false;
        this.isDilatation = false;
        this.isSpecial = true;
        this.formData.categoryRetList = [];
      }
    },
    returnRebate(rebate){
      rebate = (rebate + 15);
      // if(rebate == 0){
      //     return "无折扣";
      // }
      return (100-rebate) + "%";
    },
  },
};
</script>
